<template>
  <div class="">
    <Card class="p-m-2">
      <template #title> Ödeme Grubu</template>
      <template #content>
        <div class="top-button-area">
          <Button icon="pi pi-plus " class="p-button-sm p-mr-2 p-mb-2 yeni" label="Yeni" @click="_OdemeGrupModal()" />
        </div>
        <DataTable v-if="DataTable_render" :columns="columns" :data="OdemeGrupListesi"
          v-on:set-child-data="getDataChild" />
      </template>
    </Card>

    <Dialog v-model:visible="OdemeGrupModal" :breakpoints="{ '960px': '75vw', '640px': '100vw' }"
      :style="{ width: '50vw' }" :modal="true">
      <template #header>
        <h4>OdemeGrup</h4>
      </template>

      <div class="p-grid p-fluid p-formgrid">
        <div class="p-field p-col-6">
          <span for="spanAd">Ad</span>
          <InputText type="text" v-model="v$.Ad.$model" aria-labelledby="spanAd"
            :class="{ 'p-invalid': v$.Ad.$invalid && submitted }" />
          <small v-if="(v$.Ad.$invalid && submitted) ||
            v$.Ad.$pending.$response
            " class="p-error">{{
            v$.Ad.required.$message.replace("Value", "Name")
          }}</small>
        </div>
        <div class="p-field p-col-6">
          <span for="spanDonem">Dönem </span>
          <InputText type="text" v-model="v$.Donem.$model" aria-labelledby="spanDonem"
            :class="{ 'p-invalid': v$.Donem.$invalid && submitted }" />
          <small v-if="(v$.Donem.$invalid && submitted) ||
            v$.Donem.$pending.$response
            " class="p-error">{{
            v$.Donem.required.$message.replace("Value", "Name")
          }}</small>
        </div>
        <div class="p-field p-col-6">
          <span for="spanTur">Tür </span>
          <InputText type="text" v-model="v$.Tur.$model" aria-labelledby="spanTur"
            :class="{ 'p-invalid': v$.Tur.$invalid && submitted }" />
          <small v-if="(v$.Tur.$invalid && submitted) ||
            v$.Tur.$pending.$response
            " class="p-error">{{
            v$.Tur.required.$message.replace("Value", "Name")
          }}</small>
        </div>

        <div class="p-field p-col-6">
          <span for="spanOdemeTarih">Ödeme Tarih </span>
          <Calendar  :showIcon="true" v-model="v$.OdemeTarih.$model" aria-labelledby="spanOdemeTarih"
            :class="{ 'p-invalid': v$.OdemeTarih.$invalid && submitted }" />
          <small v-if="(v$.OdemeTarih.$invalid && submitted) ||
            v$.OdemeTarih.$pending.$response
            " class="p-error">{{
            v$.OdemeTarih.required.$message.replace("Value", "Name")
          }}</small>
        </div>

      </div>
      <template #footer>
        <Button label="Tamam" icon="pi pi-check" class="p-button-sm p-button-success"
          @click="this.handleClick(!v$.$invalid)" autofocus />
        <Button label="Kapat" icon="pi pi-times" class="p-button-sm p-button-danger" @click="this._OdemeGrupModal()" />
      </template>
    </Dialog>
  </div>
</template>

<script>
import DataTable from "../DataTable/DataTable.vue";
import GlobalServis from "../../../services/GlobalServis";

import { required, helpers } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";

export default {
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      DataTable_render: true,
      OdemeGrupListesi: [],
      OdemeGrupModal: false,
      OdemeGrupPopUpDurumu: { type: Boolean, value: null }, // True = Yeni Kullanici, False = Edit OdemeGrup
      columns: [
        { field: "Ad", header: "Ad" },
        { field: "Donem", header: "Dönem" },
        { field: "Tur", header: "Tür" },
        { field: "OdemeTarih", header: "Ödeme Tarih" },
      ],
      Ad: null,
      Donem: null,
      Tur: null,
      OdemeTarih: null
    };
  },
  methods: {
    getDataChild(getdata_child) {
      this.resetForm();
      this.OdemeGrupPopUpDurumu = false;
      this.OdemeGrupModal = !this.OdemeGrupModal;
      this.OdemeGrup_rowid = getdata_child.data.OdemeGrup_rowid;
      let data = {
        OdemeGrup_rowid: getdata_child.data.OdemeGrup_rowid,
      };
      GlobalServis.GlobalServis("POST", "GetOdemeGrupInfo", data).then(
        (res) => {
          if (res.status == 200) {
            this.v$.Ad.$model = res.data[0].Ad;
            this.v$.Donem.$model = res.data[0].Donem;
            this.v$.Tur.$model = res.data[0].Tur;
            this.v$.OdemeTarih.$model = res.data[0].OdemeTarih;
          } else if (res.status == 400) {
            this.$toast.add({
              severity: "error",
              summary: "Hata",
              detail: "Önemli bir hata meydana geldi",
              life: 3000,
            });
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Hata",
              detail: "Bilinmeyen bir hata meydana geldi",
              life: 3000,
            });
          }
        }
      );
    },
    handleClick(isFormValid) {
      this.submitted = true;
      if (isFormValid) {
        let data = {
          OdemeGrup_rowid: this.OdemeGrup_rowid,
          Ad: this.Ad,
          Donem: this.Donem,
          Tur: this.Tur,
          OdemeTarih: this.OdemeTarih,
        };
        if (this.OdemeGrupPopUpDurumu) {
          // Yeni OdemeGrup
          GlobalServis.GlobalServis("POST", "SetOdemeGrup", data).then(
            (res) => {
              if (res.status == 200 && res.data == "") {
                this.$toast.add({
                  severity: "success",
                  summary: "Başarılı",
                  detail: "OdemeGrup oluşturulmuştur",
                  life: 3000,
                });
                this.Refresh();
                this._OdemeGrupModal();
              } else if (res.status == 200 && res.data != "") {
                this.$toast.add({
                  severity: "warn",
                  summary: "Uyarı",
                  detail: res.data,
                  life: 3000,
                });
              } else {
                this.$toast.add({
                  severity: "error",
                  summary: "Hata",
                  detail: "Hata oldu",
                  life: 3000,
                });
              }
            }
          );
        } else {
          // Edit OdemeGrup
          GlobalServis.GlobalServis("POST", "UpdateOdemeGrup", data).then(
            (res) => {
              if (res.status == 200 && res.data == "") {
                this.$toast.add({
                  severity: "success",
                  summary: "Başarılı",
                  detail: "OdemeGrup düzenlemiştir",
                  life: 3000,
                });
                this.Refresh();
                this._OdemeGrupModal();
              } else if (res.status == 200 && res.data != "") {
                this.$toast.add({
                  severity: "warn",
                  summary: "Uyarı",
                  detail: res.data,
                  life: 3000,
                });
              } else {
                this.$toast.add({
                  severity: "error",
                  summary: "Hata",
                  detail: "Hata oldu",
                  life: 3000,
                });
              }
            }
          );
        }
      } else {
        this.$toast.add({
          severity: "warn",
          summary: "Gerekli Alan Uyarısı",
          detail: "Lütfen tüm alanları doldurunuz",
          life: 3000,
        });
      }
    },
    _OdemeGrupModal() {
      this.OdemeGrupPopUpDurumu = true;
      this.OdemeGrupModal = !this.OdemeGrupModal;
      if (this.OdemeGrupModal) {
        this.resetForm();
      }
    },
    resetForm() {
      // this.v$.$reset;
      this.Ad = "";
      this.Donem = "";
      this.Tur = "";
      this.OdemeTarih = "";
    },
    Refresh() {
      this.DataTable_render = false;
      this.$nextTick(() => {
        this.InitServis();
        this.DataTable_render = true;
      });
    },
    InitServis() {
      GlobalServis.GlobalServis("GET", "GetOdemeGrupTablosu", "").then(
        (res) => {
          if (res.status == 200) {
            this.OdemeGrupListesi = res.data;
          } else if (res.status == 400) {
            this.$toast.add({
              severity: "error",
              summary: "Hata",
              detail: "Önemli bir hata meydana geldi",
              life: 3000,
            });
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Hata",
              detail: "Bilinmeyen bir hata meydana geldi",
              life: 3000,
            });
          }
        }
      )

    },
  },
  mounted() {
    this.InitServis();
  },
  components: {
    DataTable,
  },
  validations() {
    return {
      Ad: {
        required: helpers.withMessage("Bu alan boş bırakılamaz", required),
      },
      Donem: {
        required: helpers.withMessage("Bu alan boş bırakılamaz", required),
      },
      Tur: {
        required: helpers.withMessage("Bu alan boş bırakılamaz", required),
      },
      OdemeTarih: {
        required: helpers.withMessage("Bu alan boş bırakılamaz", required),
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.top-button-area .p-button.yeni {
  background: linear-gradient(to left,
      var(--purple-600) 50%,
      var(--purple-700) 50%);
  background-size: 200% 100%;
  background-position: right bottom;
  transition: background-position 0.5s ease-out;
  color: #fff;
  border-color: var(--purple-700);
}

.top-button-area .p-button.yeni:hover {
  background-position: left bottom;
}

.top-button-area .p-button.yeni i {
  background-color: var(--purple-700);
}

.top-button-area .p-button.yeni:focus {
  box-shadow: 0 0 0 1px var(--purple-400);
}
</style>